import React, {
  createContext,
  useContext,
  useRef,
  useCallback,
  useState,
  useEffect,
} from "react";

type AudioType = "conversation" | "spotlight";

interface AudioContextType {
  // Core audio state
  currentAudio: {
    type: AudioType | null;
    messageId?: string;
  } | null;

  // Spotlight methods
  playAudio: (
    messageId: string,
    audioUrl: string,
    type: AudioType
  ) => Promise<void>;
  stopAudio: (type: AudioType) => void;

  // Conversation methods
  addToQueue: (
    conversationId: string,
    messageId: string,
    audioUrl: string,
    text: string
  ) => Promise<void>;
  setActiveConversation: (
    conversationId: string | null,
    worldId: string
  ) => void;
  pauseQueue: (conversationId: string) => void;
  playQueue: (conversationId: string) => Promise<void>;

  // Continuous playback methods
  setContinuousPlayback: (conversationId: string | null) => void;
  addToContinuousBuffer: (
    conversationId: string,
    messageId: string,
    audioUrl: string
  ) => void;
}

export const AudioContext = createContext<AudioContextType | null>(null);

export function AudioProvider({ children }: { children: React.ReactNode }) {
  const audioContext = useRef<globalThis.AudioContext | null>(null);
  const currentSources = useRef<{
    conversation?: AudioBufferSourceNode;
    spotlight?: AudioBufferSourceNode;
  }>({});
  const [currentAudio, setCurrentAudio] =
    useState<AudioContextType["currentAudio"]>(null);

  const conversationBuffers = useRef<
    Map<
      string,
      {
        buffer: AudioBuffer;
        messageId: string;
        source?: AudioBufferSourceNode;
        startTime?: number;
      }[]
    >
  >(new Map());

  const continuousBuffers = useRef<
    Map<
      string,
      {
        messageId: string;
        buffer: AudioBuffer;
        startTime?: number;
      }[]
    >
  >(new Map());

  const activeConversation = useRef<{
    id: string;
    source?: AudioBufferSourceNode;
    currentIndex: number;
  } | null>(null);

  const getAudioContext = useCallback(() => {
    if (!audioContext.current) {
      audioContext.current = new window.AudioContext();
    }
    return audioContext.current;
  }, []);

  // Core audio playback function
  const playAudio = useCallback(
    async (messageId: string, audioUrl: string, type: AudioType) => {
      const context = getAudioContext();

      // Stop any current audio sources
      if (currentSources.current[type]) {
        currentSources.current[type]?.stop();
        currentSources.current[type]?.disconnect();
      }

      try {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await context.decodeAudioData(arrayBuffer);

        const source = context.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(context.destination);

        currentSources.current[type] = source;
        setCurrentAudio({ type, messageId });

        // Add onended handler
        source.onended = () => {
          setCurrentAudio(null);
          currentSources.current[type] = undefined;
        };

        source.start(0);
      } catch (error) {
        console.error("Error playing audio:", error);
        setCurrentAudio(null);
      }
    },
    [getAudioContext]
  );

  const stopAudio = useCallback(
    (type: AudioType) => {
      if (currentSources.current[type]) {
        currentSources.current[type]?.stop();
        currentSources.current[type]?.disconnect();
        if (currentAudio?.type === type) {
          setCurrentAudio(null);
        }
      }
    },
    [currentAudio]
  );

  // Conversation queue methods
  const conversationQueues = useRef(
    new Map<
      string,
      {
        items: { messageId: string; audioUrl: string; text: string }[];
        isPlaying: boolean;
        currentIndex: number;
      }
    >()
  );

  const preloadConversationAudio = useCallback(
    async (conversationId: string, messageId: string, audioUrl: string) => {
      const context = getAudioContext();
      try {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await context.decodeAudioData(arrayBuffer);

        let buffers = conversationBuffers.current.get(conversationId);
        if (!buffers) {
          buffers = [];
          conversationBuffers.current.set(conversationId, buffers);
        }

        // Add buffer if not already present
        if (!buffers.some((b) => b.messageId === messageId)) {
          buffers.push({ buffer: audioBuffer, messageId });
        }
      } catch (error) {
        console.error("Error preloading conversation audio:", error);
      }
    },
    [getAudioContext]
  );

  const addToQueue = useCallback(
    async (
      conversationId: string,
      messageId: string,
      audioUrl: string,
      text: string
    ) => {
      await preloadConversationAudio(conversationId, messageId, audioUrl);
    },
    [preloadConversationAudio]
  );

  const playQueue = useCallback(
    async (conversationId: string) => {
      const queue = conversationQueues.current.get(conversationId);
      if (!queue || queue.currentIndex >= queue.items.length) return;

      const item = queue.items[queue.currentIndex];
      await playAudio(item.messageId, item.audioUrl, "conversation");
      queue.isPlaying = true;
    },
    [playAudio]
  );

  const pauseQueue = useCallback(
    (conversationId: string) => {
      const queue = conversationQueues.current.get(conversationId);
      if (queue) {
        queue.isPlaying = false;
        stopAudio("conversation");
      }
    },
    [stopAudio]
  );

  const setActiveConversation = useCallback(
    (conversationId: string | null, worldId: string) => {
      // Stop any current conversation
      if (currentAudio?.type === "conversation") {
        stopAudio("conversation");
      }

      if (conversationId) {
        const buffers = conversationBuffers.current.get(conversationId);
        if (buffers?.length) {
          // Use the existing playAudio function with the buffered data
          const firstMessage = buffers[0];
          const audioUrl = `${process.env.REACT_APP_ASSETS_BUCKET}spots/${worldId}/${conversationId}/${firstMessage.messageId}.mp3`;
          playAudio(firstMessage.messageId, audioUrl, "conversation");
        }
      }
    },
    [stopAudio, playAudio]
  );

  const addToContinuousBuffer = useCallback(
    async (conversationId: string, messageId: string, audioUrl: string) => {
      const context = getAudioContext();
      try {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await context.decodeAudioData(arrayBuffer);

        let buffers = continuousBuffers.current.get(conversationId) || [];
        buffers.push({ messageId, buffer: audioBuffer });
        continuousBuffers.current.set(conversationId, buffers);

        // If this is the active conversation, schedule the next audio
        if (activeConversation.current?.id === conversationId) {
          scheduleNextAudio(conversationId);
        }
      } catch (error) {
        console.error("Error adding to continuous buffer:", error);
      }
    },
    [getAudioContext]
  );

  const scheduleNextAudio = useCallback(
    (conversationId: string) => {
      if (
        !activeConversation.current ||
        activeConversation.current.id !== conversationId
      )
        return;

      const buffers = continuousBuffers.current.get(conversationId) || [];
      const nextIndex = activeConversation.current.currentIndex + 1;

      if (nextIndex < buffers.length) {
        const context = getAudioContext();
        const source = context.createBufferSource();
        source.buffer = buffers[nextIndex].buffer;
        source.connect(context.destination);

        source.onended = () => {
          activeConversation.current!.currentIndex = nextIndex;
          scheduleNextAudio(conversationId);
        };

        source.start();
        activeConversation.current.source = source;
      }
    },
    [getAudioContext]
  );

  const setContinuousPlayback = useCallback(
    (conversationId: string | null) => {
      // Stop current continuous playback if any
      if (activeConversation.current?.source) {
        activeConversation.current.source.stop();
        activeConversation.current.source.disconnect();
      }

      if (conversationId) {
        const buffers = continuousBuffers.current.get(conversationId);
        if (buffers?.length) {
          activeConversation.current = {
            id: conversationId,
            currentIndex: buffers.length - 1, // Start from latest message
          };
          scheduleNextAudio(conversationId);
        }
      } else {
        activeConversation.current = null;
      }
    },
    [scheduleNextAudio]
  );

  useEffect(() => {
    return () => {
      conversationBuffers.current.clear();
    };
  }, []);

  return (
    <AudioContext.Provider
      value={{
        currentAudio,
        playAudio,
        stopAudio,
        addToQueue,
        setActiveConversation,
        pauseQueue,
        playQueue,
        setContinuousPlayback,
        addToContinuousBuffer,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
}

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return context;
};

import { ObjectType, v } from "convex/values";
import { GameId, parseGameId, playerId } from "./ids";
import { Activity } from "./player";
export const serializedPlayerDescription = {
  playerId,
  name: v.string(),
  description: v.string(),
  character: v.string(),
  image: v.string(),
  bio: v.string(),
  character_id: v.string(),
  activities: v.array(
    v.object({
      description: v.string(),
      emoji: v.optional(v.string()),
      until: v.number(),
      anim: v.optional(v.string()) ///
    })
  ),
  voiceId :v.optional(v.string())
};
export type SerializedPlayerDescription = ObjectType<
  typeof serializedPlayerDescription
>;

export class PlayerDescription {
  playerId: GameId<"players">;
  name: string;
  description: string;
  character: string;
  image: string;
  bio: string;
  character_id: string;
  activities: Activity[];
  voiceId: string | undefined;
  constructor(serialized: SerializedPlayerDescription) {
    const {
      playerId,
      name,
      description,
      character,
      image,
      bio,
      character_id,
      activities,
      voiceId
    } = serialized;
    this.playerId = parseGameId("players", playerId);
    this.name = name;
    this.description = description;
    this.character = character;
    this.image = image;
    this.bio = bio;
    this.character_id = character_id;
    this.activities = activities;
    this.voiceId = voiceId;
  }

  serialize(): SerializedPlayerDescription {
    const {
      playerId,
      name,
      description,
      character,
      image,
      bio,
      character_id,
      activities,
      voiceId
    } = this;
    return {
      playerId,
      name,
      description,
      character,
      image,
      bio,
      character_id,
      activities,
      voiceId
    };
  }
}

import React, { useEffect, useRef, useContext, useState } from "react";
import useRequest from "../../apis/useRequest";
import { debounce } from "../Misc/SearchOverlay";
import { Character, SelectedCharacterProps } from "../../types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Id } from "../../convex/_generated/dataModel";
import {
  Modal,
  ModalDialog,
  ModalClose,
  DialogContent,
  Button,
  Input,
  List,
  ListItem,
  Typography,
  ListItemDecorator,
  ListItemContent,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/joy";
import { Search, RefreshCcw } from "lucide-react";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { motion } from "framer-motion";
import {
  characterSearch,
  auxLLM,
  getTownCharacterData,
  updateTown,
} from "../../apis/request";
import { StyledAvatar } from "./common/styledAvatar";
import { PlatformContext } from "../../contexts/PlatformContext";
import { TownContext } from "../../contexts/TownContext";
import { ACTIVITIES } from "../../convex/constants";
import EmojiPicker from "emoji-picker-react";
import { buildCharacterData } from "../TownPage/TownPage";
import { Alert, Snackbar } from "@mui/joy";

interface AddMemberProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedCharacters: SelectedCharacterProps[];
  setSelectedCharacters: (characters: SelectedCharacterProps[]) => void;
}

function AddMember({
  open,
  setOpen,
  selectedCharacters,
  setSelectedCharacters,
}: AddMemberProps) {
  const defaultPlan =
    "You are in 4Wall Spot, a digital town where you have the ability to walk around and interact with users and other characters.";
  const makeRequest = useRequest();

  const [searchTerm, setSearchTerm] = useState("");
  const [isEditingPlan, setIsEditingPlan] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Character[]>([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedToAdd, setSelectedToAdd] =
    useState<SelectedCharacterProps | null>(null);
  const [planGenerating, setPlanGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(PlatformContext);
  const townContext = useContext(TownContext);
  const [currentActivityIndex, setCurrentActivityIndex] = useState<
    number | null
  >(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  if (!context || !townContext) {
    throw new Error("Context not found");
  }

  const {
    NSFW,
    featuredLists,
    username,
    showAlert,
    alertInfo,
    setAlertInfo,
    closeAlert,
    userAssets,
  } = context;
  const { townName, charactersMetadata, setCharactersMetadata, town_id } =
    townContext;
  const worldStatus = useQuery(api.world.getWorldStatus, {
    worldId: town_id as Id<"worlds">,
  });
  const frozen =
    worldStatus?.worldStatus.status === "stoppedByDeveloper" ||
    worldStatus?.worldStatus.status === "inactive";
  const addCharacters = useMutation(api.world.addCharacters);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const emojiPicker = document.getElementById("emoji-picker");
    if (emojiPicker && !emojiPicker.contains(event.target as Node)) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddCharacter = async () => {
    console.log("add character");
    setAddLoading(true);
    const characterId = selectedToAdd?.character_id;
    const characterData = await makeRequest<any, any>(
      getTownCharacterData(characterId as string)
    );

    for (const character of characterData) {
      if (!character.plan) {
        character.plan = selectedToAdd?.plan || defaultPlan;
      }
      if (!character.activities || character.activities.length === 0) {
        character.activities = selectedToAdd?.activities || [];
      }
    }
    const townCharacterData = buildCharacterData(characterData);
    const awsCharacterData = [
      ...charactersMetadata,
      {
        name: townCharacterData[0].name,
        bio: townCharacterData[0].bio,
        character_id: townCharacterData[0].character_id,
        identity: townCharacterData[0].identity,
        image_url: `${process.env.REACT_APP_ASSETS_BUCKET}${townCharacterData[0].character_id}/display_picture.jpg`,
        plan: townCharacterData[0].plan,
        activities: townCharacterData[0].activities,
        voice_id: townCharacterData[0].voice_id,
      },
    ];

    try {
      await addCharacters({
        characters: townCharacterData,
        worldId: town_id as Id<"worlds">,
      });
      const response = await makeRequest<any, any>(
        updateTown(username, town_id, "characters"),
        { updatedCharacters: awsCharacterData }
      );

      setCharactersMetadata(awsCharacterData);
      if (frozen) {
        toast.success(
          "Character added successfully. Changes will reflect once the town is unfrozen."
        );
      } else {
        toast.success("Character added successfully");
      }
    } catch (error) {
      toast.error("Error adding character");
      console.error(error);
    }
    setSelectedToAdd(null);
    setOpen(false);
    setAddLoading(false);
  };

  const generateAIPlan = async (
    character: SelectedCharacterProps
  ): Promise<string> => {
    console.log("Generating Plan");
    setPlanGenerating(true);
    const systemPrompt =
      "You are a helpful assistant that generates a plan for a character based on the given context. \
      The plan should be a short, concise, and relevant objective for the character to complete. \
      Only generate a one or two sentence plan for the character. \
      The plan should be relevant to the character description and something that the character would want to do.\
      Always create a plan even if context is not provided.\
      ";
    const userPrompt = `Generate a plan for ${character.name} based on the following context: ${character.identity}.`;
    const response = await makeRequest<any, any>(auxLLM(), {
      messages: [
        { role: "system", content: systemPrompt },
        { role: "user", content: userPrompt },
      ],
    });
    console.log("Openai response", response);

    if (!response.content) {
      throw new Error("Error generating plan");
    }
    const plan = response.content || "";
    setPlanGenerating(false);
    return plan;
  };

  function parseSearchResults(searchResults: any) {
    let filteredHits = searchResults;
    if (!NSFW) {
      for (const hit of filteredHits) {
        if (hit.tags.includes("NSFW")) {
          filteredHits = filteredHits.filter((h: any) => h !== hit);
        }
      }
    }
    const characters = filteredHits.map((hit: any) => {
      const fields = hit;

      return {
        bio: fields.bio,
        character_id: fields.character_id,
        creator: fields.creator,
        name: fields.name,
        image_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/display_picture.jpg`,
        chat_background_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/chat_background.jpg`,
        interactions: fields.num_interactions,
        tags: fields.tags,
      };
    });

    return characters;
  }

  const handleActivityChange = (index: number, field: any, value: string) => {
    if (selectedToAdd) {
      const newActivities = [...selectedToAdd.activities];
      newActivities[index] = { ...newActivities[index], [field]: value };
      setSelectedToAdd({ ...selectedToAdd, activities: newActivities });
    }
  };

  const handleEmojiClick = (emojiObject: any, index: number) => {
    handleActivityChange(index, "emoji", emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const fetchSearchResults = async (value: string) => {
    setIsLoading(true);
    try {
      const response = await makeRequest<any, any>(characterSearch(), {
        query: value,
      });
      const newCharacters = parseSearchResults(response.results);
      setSearchResults(newCharacters);
      setIsLoading(false);
    } catch (error) {
      console.error("Search error:", error);
      setIsLoading(false);
    }
  };

  const debouncedSearch = useRef(debounce(fetchSearchResults, 300)).current;

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (selectedToAdd) {
      const generatePlan = async () => {
        const plan = await generateAIPlan(selectedToAdd);
        setSelectedToAdd((prevCharacter) =>
          prevCharacter &&
          prevCharacter.character_id === selectedToAdd.character_id
            ? { ...prevCharacter, plan }
            : prevCharacter
        );
      };
      generatePlan();
    }
  }, [selectedToAdd?.character_id]);

  const displayList = searchTerm.trim()
    ? searchResults
    : Array.from(
        new Set(
          [
            ...featuredLists.anime,
            ...featuredLists.isekaiRPG,
            ...featuredLists.gaming,
            ...featuredLists.moviesTV,
            ...userAssets.characters,
          ].map((character) => character.character_id)
        )
      )
        .map((id) =>
          [
            ...featuredLists.anime,
            ...featuredLists.isekaiRPG,
            ...featuredLists.gaming,
            ...featuredLists.moviesTV,
            ...userAssets.characters,
          ].find((character) => character.character_id === id)
        )
        .filter(Boolean);

  return (
    <Modal open={open}>
      <ModalDialog className="!bg-neutral-800 !border-neutral-700 !rounded-lg !transition-all !duration-300 !max-w-full !w-full !h-full md:!w-auto md:!h-auto">
        <ModalClose
          onClick={() => {
            setSelectedToAdd(null);
            setOpen(false);
          }}
        />
        <DialogContent
          className={`!flex ${isMobile ? "!flex-col" : "!flex-row"} !m-2`}
        >
          <div
            className={`${isMobile ? "w-full" : "w-80"}  ${isMobile ? "mb-10" : "mr-4 h-full"} text-fourwall-orange ${isMobile ? "" : "border-r border-white/20"}`}
          >
            <Input
              startDecorator={<Search />}
              id="searchCharacters"
              placeholder="Search Characters"
              type="search"
              fullWidth
              variant="outlined"
              value={searchTerm}
              color="warning"
              className="!bg-neutral-900 !text-white !rounded-full !border-none !w-4/5 !mx-auto !my-2"
              onChange={(e) => setSearchTerm(e.target.value)}
              autoFocus={false}
              sx={{ fontSize: "16px" }}
            />
            {!searchTerm.trim() && (
              <h3 className="text-orange-500 text-center">
                Suggested Characters
              </h3>
            )}

            <Box
              sx={{
                height: isMobile ? 200 : 400,
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "var(--orange-brand-accent)",
                  borderRadius: "4px",
                },
              }}
              className="!text-white !border-none !bg-neutral-900 p-2 rounded-lg"
            >
              <List
                sx={{
                  "--ListItemDecorator-size": "56px",
                  overflowY: "auto",
                }}
              >
                {isLoading ? (
                  <CircularProgress size="md" />
                ) : (
                  displayList.map((character) =>
                    character ? (
                      <motion.div
                        whileHover={{
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                        }}
                        whileTap={{
                          backgroundColor: "rgba(255, 255, 255, 0.3)",
                        }}
                        transition={{ duration: 0.2 }}
                      >
                        <ListItem
                          className="cursor-pointer !rounded-full m-1 "
                          key={character.character_id}
                          onClick={() => {
                            setSelectedToAdd({
                              ...character,
                              plan: defaultPlan,
                              activities: ACTIVITIES,
                              identity: character.description || "",
                              voice_id: character.voice_id || "",
                            });
                          }}
                          sx={{
                            backgroundColor: selectedCharacters.some(
                              (selectedCharacter) =>
                                selectedCharacter.character_id ===
                                character.character_id
                            )
                              ? "rgba(255, 255, 255, 0.1)"
                              : "transparent",
                          }}
                        >
                          <ListItemDecorator>
                            <StyledAvatar
                              alt={character.name}
                              size="small"
                              src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                            />
                          </ListItemDecorator>
                          <ListItemContent className="!text-white">
                            <p>{character.name}</p>
                          </ListItemContent>
                        </ListItem>
                      </motion.div>
                    ) : null
                  )
                )}
              </List>
            </Box>
          </div>
          <div
            className={`${isMobile ? "w-full" : "w-[40rem]"} ${isMobile ? "" : "ml-4"} items-center flex flex-col justify-between`}
          >
            {selectedToAdd ? (
              <>
                <h1 className="text-center text-white font-main">
                  {selectedToAdd.name}
                </h1>
                <div
                  className={`${isMobile ? "w-32 h-32" : "w-48 h-48"} rounded-lg overflow-hidden`}
                >
                  <img
                    src={`${process.env.REACT_APP_ASSETS_BUCKET}${selectedToAdd.character_id}/display_picture.jpg`}
                    alt={selectedToAdd.name || ""}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="w-full justify-center  text-white">
                  <div className="flex items-center justify-between mb-2">
                    <h2 className="text-lg font-semibold">Plan</h2>
                    <div className="flex items-center gap-2">
                      <RefreshCcw
                        size={16}
                        color="var(--primary-text-color)"
                        className="cursor-pointer hover:opacity-70"
                        onClick={async () => {
                          if (selectedToAdd) {
                            const plan = await generateAIPlan(selectedToAdd);
                            setSelectedToAdd((prevCharacter) =>
                              prevCharacter ? { ...prevCharacter, plan } : null
                            );
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full bg-black/50 rounded-lg p-4 min-h-[100px]">
                    {planGenerating ? (
                      <div className="flex items-center gap-2">
                        <Typography className="!text-orange-100 !font-main !italic">
                          Generating Plan...
                        </Typography>
                        <CircularProgress size="sm" color="warning" />
                      </div>
                    ) : (
                      <div
                        contentEditable={isEditingPlan}
                        suppressContentEditableWarning
                        onBlur={(e) => {
                          setIsEditingPlan(false);
                          if (selectedToAdd) {
                            setSelectedToAdd({
                              ...selectedToAdd,
                              plan: e.currentTarget.innerText,
                            });
                          }
                        }}
                        onClick={() => setIsEditingPlan(true)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            e.currentTarget.blur();
                          }
                        }}
                        className={`cursor-pointer text-white/90 hover:text-white transition-colors duration-200 
                                  whitespace-pre-wrap outline-none min-h-[80px] ${isEditingPlan ? "border border-orange-500/50 rounded p-2" : ""}`}
                        style={{ fontSize: "16px", lineHeight: "1.5" }}
                      >
                        {selectedToAdd?.plan || "Click to add a plan"}
                      </div>
                    )}
                  </div>

                  <div className="flex justify-center w-full">
                    <Button
                      className="!mt-4 !bg-fourwall-orange !text-white !rounded-full !px-5 !py-2 hover:!bg-orange-500 disabled:!bg-neutral-700 disabled:!text-neutral-500 "
                      disabled={planGenerating}
                      onClick={() => {
                        handleAddCharacter();
                      }}
                    >
                      {addLoading ? <CircularProgress size="sm" /> : "ADD"}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center h-full">
                <Typography className="!text-neutral-600 !font-main !font-bold !text-4xl text-center">
                  Add members to your spot
                </Typography>
              </div>
            )}
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}

export default AddMember;
